import React, { useState, useEffect } from 'react';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import SubscribeToNewsLetter from '../../lib/subscribe-to-news-letter';
import { DotLottiePlayer } from '@dotlottie/react-player';
import { handleAppDownloadCTAClick } from '../../lib/customGA4';

const SideMenuDownloadApp = (props) => {
  const graphQLResult = props?.fields;

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    const metaverseModal = new bootstrap.Modal(document.getElementById('metaverse_modal_x1'));
  }, []);

  const validateAndSubmit = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || email.length > 50 || !re.test(email)) {
      setIsValid(false);
      setIsEmpty(!email);
      return false;
    } else {
      setIsValid(true);
      setIsEmpty(false);
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateAndSubmit(email)) {
      try {
        const message = await SubscribeToNewsLetter(email);
        if (message === 'Email is already Subscribed') {
          setResponseMessage(message);
          setIsValid(true);
          setIsEmpty(false);
          setEmail('');
        } else {
          setResponseMessage(message + '!');
        }
      } catch (error) {
        setResponseMessage('Unexpected error occurred');
        setIsValid(false);
        setIsEmpty(false);
        setEmail('');
      }
    } else {
      setIsValid(false);
      setIsEmpty(!email);
      setResponseMessage('');
    }
  };

  return (
    <div
      className="modal fade metaverse_right_pos get_early_access modal_download_app"
      id="metaverse_modal_x1"
      tabIndex={-1}
      aria-labelledby="metaverse_modal_modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-slideout-right">
        <div className="modal-content">
          {/* Modal Header */}
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          {/* Modal Body */}
          <div className="modal-body">
            <div className="modal_wrapper_top">
              <div className="top-logo-video">
                {graphQLResult?.stickyDownloadAppLogoVideo?.value?.href &&
                  (graphQLResult?.stickyDownloadAppLogoVideo?.value?.href.split('.').pop() ===
                    'lottie' ||
                  graphQLResult?.stickyDownloadAppLogoVideo?.value?.href.split('.').pop() ===
                    'json' ? (
                    <DotLottiePlayer
                      className="leaflet-lottie-logo-anim"
                      src={graphQLResult?.stickyDownloadAppLogoVideo?.value?.href}
                      background="transparent"
                      speed={1}
                      autoplay
                      loop
                    ></DotLottiePlayer>
                  ) : (
                    <video
                      width="300"
                      height="300"
                      controlsList="nofullscreen nodownload"
                      muted
                      loop
                      autoPlay
                      playsInline
                      style={{
                        position: 'absolute',
                        pointerEvents: 'none',
                        top: '-158px',
                        left: '40%',
                      }}
                    >
                      <source
                        src={graphQLResult?.stickyDownloadAppLogoVideo?.value?.href}
                        type="video/mp4"
                      />
                    </video>
                  ))}
                {graphQLResult?.stickyDownloadAppLogo?.value?.src && (
                  <Image field={graphQLResult?.stickyDownloadAppLogo} loading="lazy" />
                )}
              </div>
              <RichText
                className="modal-title"
                id="metaverse_modal_modalLabel"
                field={graphQLResult?.heading}
              />
              <p className="popup-p">
                <Text field={graphQLResult?.subheading1} />
              </p>
              {graphQLResult?.siteLink?.value?.url && (
                <a
                  href={graphQLResult?.siteLink?.value?.url}
                  className="know_more_about-us download_app_btn"
                  onClick={() =>
                    handleAppDownloadCTAClick(
                      graphQLResult?.siteLink?.text,
                      graphQLResult?.heading?.value
                    )
                  }
                >
                  {graphQLResult?.siteLink?.value?.text}{' '}
                  <Image field={graphQLResult?.googleappimage} loading="lazy" />
                  <Image field={graphQLResult?.appleappimage} loading="lazy" />
                </a>
              )}
            </div>
            <div className="modal_form_wrapper"></div>
          </div>
          <div className="modal_body_bottom">
            <Image className="mob-img-mb" field={graphQLResult?.image1} loading="lazy" />
            <div className="modal_scanner_Wrapper">
              <Image field={graphQLResult?.image2} loading="lazy" />
              <RichText tag="p" field={graphQLResult?.Contents} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SideMenuDownloadApp);
